<template>
    <main id="main" class="o-main">
        <div class="o-container">
            <p>Beim Login ist ein Fehler aufgetreten, bitte laden Sie die Seite neu.</p>
        </div>
    </main>
</template>

<script>
    export default {
        name: "OidcCallbackError"
    }
</script>